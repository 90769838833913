import React from 'react'
import styles from './styles.module.css'
import { Link } from "gatsby"

const ForgotPassword = () => (
  <div className={styles.container}>
    <div className={styles.box}>
      <div>
        <img src={'https://picsum.photos/450/450'}/>
      </div>
      <div className={styles.form}>
        <h1>Recuperar contraseña</h1>
        <input placeholder={'Email'}/>
        <button className={'primary'} style={{width: '100%'}}>Recuperar</button>
        <div className={styles.accountOptions}>
          <Link to={'/login'}>Volver al login</Link>
          <Link to={'/register'}>Registrarme</Link>
        </div>
      </div>
    </div>
  </div>
)

export default ForgotPassword